require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('bootstrap-datepicker')
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.th.min')

const path = require('path');
const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const ProgressBar = require('@uppy/progress-bar')
const XHRUpload = require('@uppy/xhr-upload')

import 'magnific-popup'
import 'select2'

import tippy from 'tippy.js'
import Chart from 'chart.js'
import Swal from 'sweetalert2'

import { toastMessage, alertMessage, isEmailValid, getReportPdfPath } from './../helper/'

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')

require('tui-color-picker/dist/tui-color-picker.css')

import 'tippy.js/dist/tippy.css'
import 'magnific-popup/dist/magnific-popup.css'

// window.$ = $
global.$ = $
global.Swal = Swal

let initPlatformScripts = () => {
  
  // increase chart and legend padding
  Chart.Legend.prototype.afterFit = function() {
    this.height = this.height + 16
  }
  
  let sidebar = document.getElementById('platform_sidebar')
  let switcherWrapper = document.querySelector('._header-switcher')
  let userAvatar = document.querySelector('.header-avatar')
  
  if (sidebar) {
    let navigation = sidebar.querySelector('.platform-navigation')
    let navLinks = navigation.querySelectorAll(':scope > li')
    navLinks.forEach(nav => {
      let link = nav.querySelector('a')
      link.addEventListener('click', (e) => {
        if (nav.classList.contains('has-child')) {
          e.preventDefault()
        }
        navLinks.forEach(nl => nl.classList.remove('active'))
        nav.classList.add('active')
      })
    })
  }
  
  if (switcherWrapper) {
    let switcherLink = switcherWrapper.querySelector('a')
    $(switcherLink).magnificPopup({
      type: 'inline',
      preloader: false,
      modal: true,
      closeOnBgClick: false
    })
  }
  
  $(document).on('click', '.mfp-close', e => {
    let magnificPopup = $.magnificPopup.instance; 
    magnificPopup.close();
  })
  
  if (userAvatar) {
    let userFirstName = userAvatar.dataset.name
    tippy(userAvatar, {
      content: userFirstName,
      placement: 'bottom'
    })
  }
  
  $('#submit_client_button').on('click', (e) => {
    e.preventDefault()
    if (!$('#contact_first_name').val() || !$('#contact_last_name').val() || !$('#contact_contact_type').val() ) {
      Swal.fire({
        icon: 'error',
        title: 'การแจ้งเตือน',
        text: 'กรุณากรอกข้อมูลในช่องที่มีเครื่องหมาย * ให้ครบ',
        confirmButtonText: 'ตกลง',
        animation: false
      })
      return false
    }
    $('#preloader').addClass('active');
    $('#client_form').submit();
  })
  
  $('#submit_project_button').on('click', (e) => {
    e.preventDefault()
    if ( !$('#project_name').val() || !$('#project_contact_id').val() ) {
      Swal.fire({
        icon: 'error',
        title: 'การแจ้งเตือน',
        text: 'กรุณากรอกข้อมูลในช่องที่มีเครื่องหมาย * ให้ครบ',
        confirmButtonText: 'ตกลง',
        animation: false
      })
      return false
    }
    $('#preloader').addClass('active');
    $('#project_form').submit();
  })
  
  if ( $('.jd-date-picker').length > 0 ) {
    $('.jd-date-picker').datepicker({
      language: 'th',
      autoclose: true,
      format: 'dd/mm/yyyy',
      todayHighlight: true,
    }).on('changeDate', (e) => {
      let current = e.currentTarget
      if ( current.getAttribute('class').includes('start-date') ) {
        $('.deliver-date').val('').datepicker('setStartDate', current.value)
      }
    })
  }
  
  if ( $('#role_form').length > 0 ) {
    $('#role_button').on('click', e => {
      let form = $('#role_form')
      let name = form.find('#role_name').val()
      let scopes = form.find('#role_project_scopes').val()
      // checking name
      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'การแจ้งเตือน',
          text: 'กรุณากรอกชื่อกลุ่มผู้ใช้งานก่อน',
          confirmButtonText: 'ตกลง',
          animation: false
        })
        return false
      }
      // checking scopes
      if (!scopes) {
        Swal.fire({
          icon: 'error',
          title: 'การแจ้งเตือน',
          text: 'กรุณาเลือกสิทธิ์การเข้าถึงโครงการก่อน',
          confirmButtonText: 'ตกลง',
          animation: false
        })
        return false
      }
      $('#preloader').addClass('active');
      form.submit()
    })
  }
  
  if ( $('.jd-multi-select').length > 0 ) {
    $('.jd-multi-select').select2({
      minimumResultsForSearch: Infinity
    })
  }
  
  if ( $('#staff_form').length > 0 ) {
    $('#staff_button').on('click', e => {
      let form = $('#staff_form')
      let name = form.find('#user_first_name').val()
      let email = form.find('#user_email').val()
      let password = form.find('#user_password').val()
      let confirmPassword = form.find('#user_password_confirmation').val()
      let role = form.find('#user_role_id').val()
      // checking name
      if (!name) {
        alertMessage('กรุณาระบุชื่อให้กับผู้ใช้งานคนนี้ก่อน')
        return false
      }
      // checking email
      if (!email && isEmailValid(email)) {
        alertMessage('กรุณาตรวจสอบอีเมล์และรูปแบบให้ถูกต้อง')
        return false
      }
      // checking password
      if (!password && password.length < 6) {
        alertMessage('รหัสผ่านควรมีความยาวมากกว่า 6 ตัวอักษร')
        return false
      }
      if (password && !confirmPassword) {
        alertMessage('กรุณากรอกยืนยันรหัสผ่านอีกครั้ง')
        return false
      }
      if (password !== confirmPassword) {
        alertMessage('รหัสผ่านทั้งสองช่องที่คุณกรอกไม่เหมือนกัน')
        return false
      }
      // checking role
      if (!role) {
        alertMessage('กรุณาเลือกกลุ่มผู้ใช้งานให้บุคคลนี้ก่อน')
        return false
      }
      $('#preloader').addClass('active');
      form.submit()
    })
  }
  
  if ( $('#update_profile_form').length > 0 ) {
    $('#profile_button').on('click', e => {
      let form = $('#update_profile_form')
      let name = form.find('#user_first_name').val()
      let email = form.find('#user_email').val()
      let password = form.find('#user_password').val()
      let confirmPassword = form.find('#user_password_confirmation').val()
      // checking name
      if (!name) {
        alertMessage('ชื่อของคุณไม่ควรปล่อยว่างเอาไว้')
        return false
      }
      // checking email
      if (!email && isEmailValid(email)) {
        alertMessage('กรุณาตรวจสอบอีเมล์และรูปแบบให้ถูกต้อง')
        return false
      }
      if (password && !confirmPassword) {
        alertMessage('กรุณากรอกยืนยันรหัสผ่านอีกครั้ง')
        return false
      }
      // checking password
      if (password && password.length < 6) {
        alertMessage('รหัสผ่านควรมีความยาวมากกว่า 6 ตัวอักษร')
        return false
      }
      if (password !== confirmPassword) {
        alertMessage('รหัสผ่านทั้งสองช่องที่คุณกรอกไม่เหมือนกัน')
        return false
      }
      $('#preloader').addClass('active');
      form.submit()
    })
  }
  
  if ( $('#answer_form').length > 0 ) {
    $('#submit_answer_button').on('click', e => {
      let form = $('#answer_form')
      let category = form.find('#answer_category').val()
      let name = form.find('#answer_word').val()
      // checking category
      if (!category) {
        alertMessage('หมวดคำตอบไม่ควรปล่อยว่างเอาไว้')
        return false
      }
      // checking name
      if (!name) {
        alertMessage('ชื่อของคุณไม่ควรปล่อยว่างเอาไว้')
        return false
      }
      $('#preloader').addClass('active');
      form.submit()
    })
  }

  if ( $('#answer_form_en').length > 0 ) {
    $('#submit_answer_en_button').on('click', e => {
      let form = $('#answer_form_en')
      let category = form.find('#answer_en_category').val()
      let name = form.find('#answer_en_word').val()
      // checking category
      if (!category) {
        alertMessage('หมวดคำตอบไม่ควรปล่อยว่างเอาไว้')
        return false
      }
      // checking name
      if (!name) {
        alertMessage('ชื่อของคุณไม่ควรปล่อยว่างเอาไว้')
        return false
      }
      $('#preloader').addClass('active');
      form.submit()
    })
  }
  
  if ( $('#upload_area_wrapper').length > 0 ) {
    let uploaderId = $('#uploader_id').val()
    let csrfToken = document.querySelector("meta[name=csrf-token]").content
    let projectId = $('#upload_project_id').val()
    let reportId = $('#report_id').val()
    
    $('#upload_project_folder').on('change', e => {
      if ($(this).val() !== '') {
        $('#upload_area_wrapper').addClass('active')
      }
    });

    // $('#inspect_round').on('change', e => {
    //   if ($(this).val() !== '' && $('#upload_project_folder').val() !== '') {
    //     $('#upload_area_wrapper').addClass('active')
    //   }
    // });
    
    let uppy = new Uppy({ 
      allowMultipleUploads: true, 
      debug: true, 
      autoRetry: false ,
      restrictions: {
        allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif']
      }
    })
    .use(Dashboard, {
      inline: true,
      width: '100%',
      height: 400,
      target: '#upload_area_wrapper',
      waitForThumbnailsBeforeUpload: true,
      showProgressDetails: true

    })
    // .use(ProgressBar, { target: '#upload_progress_bar', hideAfterFinish: false })
    .use(XHRUpload, {
      headers: { 'X-CSRF-Token' : csrfToken },
      withCredentials: true,
      bundle: false,
      formData: true,
      limit: 1,
      endpoint: `/platform/upload/project/${projectId}/folder`
    })
    // all events goes here.
    uppy.on('file-added', (file) => {
      uppy.setFileMeta(file.id, {
        folder: $('#upload_project_folder').val(),
        report_id: reportId,
        user_id: uploaderId
      })
    })
    uppy.on('upload', (data) => {
      $('#preloader').addClass('active')
    })
    uppy.on('error', (error) => {
      $('#preloader').removeClass('active')
      alertMessage(`เกิดข้อผิดพลาด: ${error.stack}`, true)
    })
    uppy.on('upload-error', (error) => {
      $('#preloader').removeClass('active')
      alertMessage(`มีบางไฟล์ผิดพลาด หรือนามสกุลภาพไม่ถูกต้อง: ${error.stack}`, true)
    })
    uppy.on('complete', (result) => {
      $('#preloader').removeClass('active')
      alertMessage('อัพโหลดรูปภาพทั้งหมดเข้าสู่ระบบเรียบร้อยแล้ว', true)
      uppy.reset()
    })
    
  }
  
  if ( $('#build_report_button').length > 0 ) {
    $('#build_report_button').on('click', e => {
      $('#preloader').addClass('active')
    })
  }
  
  if ( $('.defect-update-button').length > 0 ) {
    $('.defect-update-button').on('click', e => {
      if (e) e.preventDefault()
      let group = $(e.currentTarget).parents('.defect-item')
      let id = $(e.currentTarget).data('id')
      let params = {
        id: id,
        defect_type: $(group).find('.defect-type').val(),
        note: $(group).find('.note').val(),
        location: $(group).find('.location').val(),
        description: $(group).find('.description').val(),
        defect_status: $(group).find('.qc').val(),
        defect_level: $(group).find('.defect-level').val(),
        // defect_status: $(group).find('.defect-status:checked').val()
      }
      $.ajax({
        type: 'POST',
        url: '/platform/reports/update-defect',
        data: { defect: params }
      }).done( response => {
        toastMessage(response.payload, response.status === 202 ? true : false)
      })
    })
  }

  if ( $('.defect-item').length > 0 ) {
    // update the rest defect when selected changed - status
    let qc = $('.defect-item').find('.qc')
    let note = $('.defect-item').find('.note')
    let location = $('.defect-item').find('.location')
    let currentButton = $('.defect-item').find('.defect-update-button')

    qc.on('change', e => {
      let selectedValue = e.currentTarget.value
      let thisDefect = $(e.currentTarget).parents('.defect-item')
      let thisDefectId = $(thisDefect).data('defect-number')
      let updateBulkStatus = $(`input[name=defect_update_status_${thisDefectId}]:checked`)
      // if select bulk, bulk update
      if (updateBulkStatus.val() == 'bulk') {
        let followDefects = $(thisDefect).nextAll()
        currentButton.trigger('click')
        followDefects.each( (index, defect) => {
          // disabled change event first
          let qcInput = $(defect).find('.qc')
          let updateButton = $(defect).find('.defect-update-button')
          $('body').off('change', qcInput)
          $(qcInput).val(selectedValue)
          updateButton.trigger('click')
        })
      } else {
        let updateButton = $(thisDefect).find('.defect-update-button')
        updateButton.trigger('click')
      }
    })
    
    // update the rest defect when selected changed - note
    note.on('change', e => {
      let selectedValue = e.currentTarget.value
      let thisDefect = $(e.currentTarget).parents('.defect-item')
      let thisDefectId = $(thisDefect).data('defect-number')
      let updateBulkStatus = $(`input[name=defect_update_status_${thisDefectId}]:checked`)
      if (updateBulkStatus.val() == 'bulk') {
        // get all ids of defect after current
        let defectIds = []
        defectIds.push($(thisDefect).data('defect-number'))
        $(thisDefect).nextAll().each( (_, defect) => {
          defectIds.push($(defect).data('defect-number'))
        })
        // ajax update all defect
        $('#preloader').addClass('active')
        $.ajax({
          type: 'POST',
          url: '/platform/reports/bulk-update-defect',
          data: { 
            defect_ids: defectIds,
            update_type: 'note',
            update_value: selectedValue,
          },
          success: function(_) {
            $(thisDefect).nextAll().each( (_, defect) => {
              let newOption = new Option(selectedValue, selectedValue, true, true)
              let noteInput = $(defect).find('.note')
              $(noteInput).append(newOption)
              $(noteInput).val(selectedValue)
            })
            $('#preloader').removeClass('active')
          }
        })
      } else {
        let updateButton = $(thisDefect).find('.defect-update-button')
        updateButton.trigger('click')
      }
    })

    // update the rest defect when selected changed - location
    location.on('change', e => {
      let selectedValue = e.currentTarget.value
      let thisDefect = $(e.currentTarget).parents('.defect-item')
      let thisDefectId = $(thisDefect).data('defect-number')
      let updateBulkStatus = $(`input[name=defect_update_status_${thisDefectId}]:checked`)
      if (updateBulkStatus.val() == 'bulk') {
        let defectIds = []
        defectIds.push($(thisDefect).data('defect-number'))
        $(thisDefect).nextAll().each( (_, defect) => {
          defectIds.push($(defect).data('defect-number'))
        })
        // ajax update all defect
        $('#preloader').addClass('active')
        $.ajax({
          type: 'POST',
          url: '/platform/reports/bulk-update-defect',
          data: { 
            defect_ids: defectIds,
            update_type: 'location',
            update_value: selectedValue,
          },
          success: function(_) {
            $(thisDefect).nextAll().each( (_, defect) => {
              let newOption = new Option(selectedValue, selectedValue, true, true)
              let noteInput = $(defect).find('.location')
              $(noteInput).append(newOption)
              $(noteInput).val(selectedValue)
            })
            $('#preloader').removeClass('active')
          }
        })
      } else {
        let updateButton = $(thisDefect).find('.defect-update-button')
        updateButton.trigger('click')
      }
    })

    // update description when lose focus
    let description = $('.defect-item').find('.description')
    let oldValue = ''
    description.on('focus', e => {
      oldValue = e.currentTarget.value
    })
    description.on('blur', e => {
      if (oldValue != e.currentTarget.value) {
        let updateButton = $(e.currentTarget).parents('.defect-item').find('.defect-update-button')
        updateButton.trigger('click')
      }
    })

  }
  
  if ( $('.delect-defect-button').length > 0 ) {
    $('.delect-defect-button').on('click', e => {
      if (e) e.preventDefault()
      let removeId = $(e.currentTarget).data('id')
      let conf = confirm("ต้องการลบรูปภาพหรือไม่?")
      if (conf) {
        $.ajax({
          type: 'DELETE',
          url: '/platform/reports/delete-defect',
          data: { id: removeId }
        }).done( response => {
          if (response.status === 202) {
            $('#preloader').addClass('active')
            window.location.reload()
          } else {
            toastMessage(response.payload, false)
          }
        })
      }
    })
  }
  
  if ( $('.build-cover-button').length > 0 ) {
    $('.build-cover-button').on('click', e => {
      if (e) e.preventDefault()
      $('#preloader').addClass('active')
      let id = $(e.currentTarget).data('id')
      let number = $(e.currentTarget).data('number')
      $.ajax({
        type: 'POST',
        url: '/platform/reports/generate-cover',
        data: { id, number }
      }).done( response => {
        $('#preloader').removeClass('active')
        if (response.status === 200) {
          let pdfTab = window.open( getReportPdfPath(response.payload) , '_blank');
          pdfTab.focus();
        } else {
          toastMessage('ไม่สามารถสร้างไฟล์ได้ในขณะนี้ กรุณาลองอีกครั้ง', false)
        }
      })
    })
  }
  
  if ( $('.build-report-button').length > 0 ) {
    $('.build-report-button').on('click', e => {
      if (e) e.preventDefault()
      $('#preloader').addClass('active')
      let id = $(e.currentTarget).data('id')
      let type = $(e.currentTarget).data('type')
      let number = $(e.currentTarget).data('number')
      $.ajax({
        type: 'POST',
        url: '/platform/reports/generate-pdf',
        data: { id, number, type }
      }).done( response => {
        $('#preloader').removeClass('active')
        if (response.status === 200) {
          let pdfTab = window.open( getReportPdfPath(response.payload) , '_blank');
          pdfTab.focus();
        } else {
          toastMessage('ไม่สามารถสร้างไฟล์ได้ในขณะนี้ กรุณาลองอีกครั้ง', false)
        }
      })
    })
  }
  
  if ( $('.select2.add-options').length > 0 ) {
    $('.select2.add-options').select2({
      placeholder: 'เลือกจากรายการ',
      allowClear: true,
      tags: true
    })
  }

  if ( $('.mobile-menu').length > 0 ) {
    $('.mobile-menu').on('click', function(e){
      if (e) e.preventDefault()
      let img = $(this).find('img')
      let sidebar = $('.platform-sidebar')
      $(sidebar).toggleClass('active')
      if ( $(sidebar).hasClass('active') ) {
        img.attr('src', '/img/mobile-nav-close.png')
      } else {
        img.attr('src', '/img/mobile-nav.png')
      }
    })
  }

  if ( $('#switch_project_button').length > 0 ) {
    $('#switch_project_button').on('click', e => {
      $('#preloader').addClass('active')
      $('#switcher_form').submit()
    })
  }

  if ( $('#delete_all_defects').length > 0 ) {
    $('#delete_all_defects').on('click', e => {
      let conf = confirm('ต้องการลบจริงหรือไม่? หากลบแล้วจะไม่สามารถกู้ข้อมูลคืนกลับมาได้')
      if (conf) {
        let reportId = $(e.currentTarget).data('id')
        let type = $(e.currentTarget).data('type')
        $('#preloader').addClass('active')
        $.ajax({
          type: 'DELETE',
          url: '/platform/reports/delete-all-defects',
          data: { id: reportId, type: type }
        }).done( response => {
          if (response.status === 200) {
            window.location.reload()
            return false
          } else {
            $('#preloader').removeClass('active')
            alertMessage(response.payload)
            return false
          }
        })
      }
    })
  }

  if ( $('.clone-report-button').length > 0 ) {
    $('.clone-report-button').on('click', e=> {
      let conf = confirm('ต้องการโคลนนิ่งรายงานฉบับนี้ใช่หรือไม่?')
      let reportId = $(e.currentTarget).data('id')
      if (conf) {
        $('#preloader').addClass('active')
        $.ajax({
          type: 'POST',
          url: '/platform/reports/clone-report',
          data: { id: reportId }
        }).done( response => {
          if (response.status === 200) {
            window.location.reload()
            return false
          } else {
            $('#preloader').removeClass('active')
            alertMessage(response.payload)
            return false
          }
        }) 
      }
    })
  }

  if ( $('.build-full-report-button').length > 0 ) {
    $('.build-full-report-button').on('click', e => {
      if (e) e.preventDefault()
      let conf = confirm('ต้องการสร้างเล่มเต็มหรือไม่? ขั้นตอนนี้อาจจะใช้เวลานานนิดนึง กรุณาอย่าปิดหน้าต่างนี้')
      let reportId = $(e.currentTarget).data('id')
      let number = $(e.currentTarget).data('number')
      if (conf) {
        $('#preloader').addClass('active')
        $.ajax({
          type: 'POST',
          url: '/platform/reports/generate-full',
          data: { id: reportId, number: number }
        }).done( response => {
          $('#preloader').removeClass('active')
          if (response.status === 200) {
            let pdfTab = window.open( getReportPdfPath(response.payload) , '_blank');
            pdfTab.focus();
          } else {
            alertMessage(response.payload)
            return false
          }
        }) 
      }
    })
  }

  if ( $('#select_project').length > 0 ) {
    $('#select_project').select2({
      width: '100%',
      dropdownParent: $('#switcher_modal'),
    })
  }

  if ( $('.after-defect-image-browser').length > 0 ) {
    $('.after-defect-image-browser').on('click', e => {
      let parent = $(e.currentTarget).parents('.after-browser-group')
      let filePicker = parent.find('input[type=file]')
      filePicker.click()
    })
  }

  if ( $('.after-defect-image-file').length > 0 ) {
    $('.after-defect-image-file').on('change', e => {
      let files = e.currentTarget.files
      if (files.length > 0) {
        let defectItemGroup = $(e.currentTarget).parents('.defect-item')
        let defectType = defectItemGroup.find('.defect-type').val()
        let defectId = $(e.currentTarget).data('id')
        let projectId = $(e.currentTarget).data('project')
        let formData = new FormData()
        // append image, project id, defect type and defect id
        formData.append('defect_id', defectId)
        formData.append('defect_type', defectType)
        formData.append('project_id', projectId)
        formData.append('image', files[0])
        $('#preloader').addClass('active')
        $.ajax({
          type: 'POST',
          url: '/platform/reports/upload-after-defect-image',
          cache: false,
          contentType: false,
          processData: false,
          data: formData,
        }).done( response => {
          $('#preloader').removeClass('active')
          if (response.status === 200) {
            toastMessage(response.payload.message, true)
            let defectAfterImage = defectItemGroup.find('.defect-before-after-wrapper.after').find('img')
            if (defectAfterImage) defectAfterImage.attr('src', response.payload.image_url)
          } else {
            alertMessage(response.payload, false)
          }
        })
      }
    })
  }  
  
}

document.addEventListener('turbolinks:before-visit', () => {
  $('#preloader').addClass('active')
})

document.addEventListener('turbolinks:load', () => {
  $('#preloader').removeClass('active')
  initPlatformScripts()
})